import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonModalService } from '../../events/services/common-modal.service';
import { TranslationService } from 'src/app/shared/service/translation.service';
import { DataServiceServiceProfile } from '../services/profile-data-service.service';
import { AppConstants } from 'src/app/shared/constants/constants';
import { ToastService, ToastType } from 'src/app/shared/service/toast.service';
import { LoadingSpinnerService } from 'src/app/shared/service/loading-spinner.service';
@Component({
  selector: 'app-create-edit-profile',
  templateUrl: './create-edit-profile.component.html',
  styleUrls: ['./create-edit-profile.component.scss']
})
export class CreateEditProfileComponent implements OnInit {

  constructor(
    private commonModalService:CommonModalService,
    private fb:UntypedFormBuilder,
    private translationService:TranslationService,
    private profileDataService:DataServiceServiceProfile,
    private toastService:ToastService,
    private _loadingSpinnerService: LoadingSpinnerService
  ) { }
  public aliasForm!: UntypedFormGroup;
  @ViewChild('editPopup') editPopup: TemplateRef<any> | undefined;
  showEditPopup:boolean= true
  popupType:string='large-popup'
  imageUrl:any
  fileToUpload: any;
  translationData:any;
  public buttonNames: any = AppConstants.COMMON_BUTTON_LABELS;
  public appConstants = AppConstants;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  maintainAspectRatio: boolean = true;
  aspectRatio: number = 1;
  containWithinAspectRatio: boolean = false;
  cropperMinWidth: number = 176;
  cropperMinHeight: number = 176;
  maxImageSize:number = 5000000;
  minImageSize:number = 176;
  imageName: string = ''
  isHeaderVisible: boolean = true
  formSubmitted: boolean = false
  initialFormValues: any;
  imageSizeIsSmall: boolean = false
  imageSizeIsLarge: boolean = false
  showToast = false;
  toastMessage: any;
  position: any;
  toastType: any;
  leftPosition: any;

  @Output()  cancelClicked = new EventEmitter<any>();
  @Output()  getMemberListData = new EventEmitter<string>();
  @Input() profileData:any;

  ngOnInit(): void {
    this.commonModalService._modalHeaderIsCloseBtnVisible.next(true)
    this.commonModalService._modalHeaderIsSaveBtnVisible.next(true)
    this.commonModalService._modalHeaderTitle.next('BP_PM_EDIT_PROFILE')
    this.imageUrl = localStorage.getItem('profileImage') || this.profileData?.profileImageUrl;
    this.translationService
    .getTranslationDataAsObservable()
    .subscribe((translationData: any) => {
      this.translationData = translationData;
    });
    this.aliasForm=this.fb.group({
      'profileName':[this.profileData?.profileName,Validators.required],
      'profileDescription':[this.profileData?.profileDescription,[Validators.required,Validators.maxLength(256)]],
      'businessWebsite':[this.profileData?.businessWebsite,[Validators.required,this.urlValidation]],
      'profileImageDeletion':["false"]
    })
    this.initialFormValues = { ...this.aliasForm.value };
    this.toastService.toastDetails.subscribe((status: any) => {
      this.showToast = status.isShown;
      this.toastMessage = status.message;
      this.toastType = status.toastType;
      this.position = status.position;
      this.leftPosition = status.leftPosition;
    });
  }

  urlValidation(control:any){
    const url = control.value;
    // Regex to match a valid URL with or without protocol (http, https, ftp, etc.)
    const regex =AppConstants.URL_VALIDATION_REGEX_PATTERN

    return regex.test(url) ? null : { invalidUrl: true }; 
  }
  public saveProfileData(): void {
    this.formSubmitted = true;
    if(this.aliasForm.valid && this.isFormChanged()){
    this._loadingSpinnerService.show() 
    const payload=
    {
      profileName:this.aliasForm.value.profileName,
      profileDescription:this.aliasForm.value.profileDescription,
      businessWebsite: this.aliasForm.value.businessWebsite,
      profileImageDeletion:"false"
    }
    this.profileDataService.updateProfile(payload,this.aliasForm.value.uploadedImage).subscribe(
      (data: any)=>{
        let profileImageUrl = data.body.profileImageUrl
        let profileName = data.body.profileName
        this.showEditPopup=false
        this.cancelClicked.emit(true)
        this.getMemberListData.emit(data.body);
        localStorage.setItem('profileName',profileName)
        localStorage.setItem('profileImage',profileImageUrl)
      }
    )
  }
   }

  handleFileInput(event:any) {
    this._loadingSpinnerService.show() 
    const fileInput = event.target as HTMLInputElement;
    const file = event.target.files[0];
    let fileName = file.name;
    let fileSize = file.size
    this. checkFileType(fileName)
    if (file) {
      this.imageChangedEvent = event;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageChangedEvent = e.target?.result;
        fileInput.value = ''; 
        const img = new Image();
        img.src = e.target?.result;
        img.onload = () => {
          let imageWidth = img.width;
          let imageHeight = img.height;
          this.checkFileValidation(imageWidth,imageHeight,fileSize)
        };
      };
     
      reader.readAsDataURL(file); 
    }
    
  }
  closeModal(){
   this.showEditPopup=false
   this.cancelClicked.emit(true)
  }
  closeCropperModal() {
    let modal = document.getElementById("cropModal");
    if (modal) {
      modal.style.display = "none";
    }
    this.imageSizeIsSmall = false;
    this.imageSizeIsLarge = false;
  }

  onCropped(event: any) {
    this.croppedImage = event.base64;
  }

  onImageLoaded(event: any) {
    let modal = document.getElementById("cropModal");
      if (modal) {
        modal.style.display = "block";
      }
      this._loadingSpinnerService.hide() 
  }

  loadProfilePicture($event: any){
      this.fileToUpload = this.croppedImage
      this.imageUrl = this.croppedImage
      const converToBlob = this.b64toBlob(this.croppedImage);
      const convertedFile = new File([converToBlob], this.imageName, { type: 'image/jpeg' });
      this.aliasForm.addControl('uploadedImage', new UntypedFormControl(converToBlob))
      this.closeCropperModal()    
  }

  b64toBlob(dataURI: any) {
    const byteString: string = atob(dataURI.split(',')[1]);
    const ab: ArrayBuffer = new ArrayBuffer(byteString.length);
    const ia: Uint8Array = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  isFormChanged(): boolean {
    return JSON.stringify(this.aliasForm.value) !== JSON.stringify(this.initialFormValues);
  }

  checkFileValidation(imageWidth: number, imageHeight: number, imageSize: number){
     if(imageWidth < this.minImageSize || imageHeight < this.minImageSize ){
      this.imageSizeIsSmall = true;
    }else if(imageSize > this.maxImageSize){
      this.imageSizeIsLarge = true;
    }

  }
  checkFileType(fileName: string,){
    let fileExtension = fileName.split(".");
    let extension = fileExtension[fileExtension.length - 1].toLowerCase();
    if (extension != "jpg" && extension != "jpeg" && extension != "png") {
      this.showToast = true
      this._loadingSpinnerService.hide()
      this.toastService.show(ToastType.Error, 'BP_CM_INVALID_IMAGE_FORMAT_MSG', '8%', '40%');
      this.closeCropperModal()
      setTimeout(() => {
        this.toastService.hide();
        this.showToast = false
      }, AppConstants.MIN_TIME_TOASTER);
    }
  }

}
