<ng-template #editPopup>
    <div *ngIf="showToast" class="toast-style">
        <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition"></app-toast>
    </div>
    <div class="info-section">
        <form [formGroup]="aliasForm">
            <div class="amg-row info-content">
                <div class="clm-9">
                    <div class="profile-alias-form">
                        <div class="form-row">
                            <div class="form-item material full-w">
                                <input id="alias-info-alias-name" maxlength="50" formControlName="profileName" type="text"
                                    autocomplete="off"
                                    [ngClass]="{'input-text required': formSubmitted &&  !aliasForm.get('profileName')?.valid, 'not-empty': aliasForm.value.profileName.length}"
                                    />
                                <label>{{'BP_PM_AN' | customTranslation:translationData}}*</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-item material full-w">
                                <textarea id="alias-info-alias-desc" formControlName="profileDescription"
                                [ngClass]="{'input-text required': formSubmitted &&  !aliasForm.get('profileDescription')?.valid, 'content-available': aliasForm.value.profileDescription.length }"
                                ></textarea>
                                <label for="">{{ appConstants.COMMON_LABELS.DESCRIPTION | customTranslation:translationData}}</label>
                            </div>
                            <div class="form-note-text right">{{aliasForm.value.profileDescription.length}} {{'BP_PM_CHAR_LIMIT' | customTranslation:translationData}} {{'BP_CM_CHARACTERS' | customTranslation:translationData}}</div>
                        </div>
                        <div class="form-row">
                            <div class="form-item material full-w">
                                <input id="alias-info-alias-website" maxlength="50" formControlName="businessWebsite"
                                    type="text" [ngClass]="{'input-text required': formSubmitted && !aliasForm.get('businessWebsite')?.valid, 'not-empty': aliasForm.value.businessWebsite.length}"/>
                                <label>{{'BP_PM_BW' | customTranslation:translationData}}*</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clm-3">
                    <div class="upload-img">
                        <div class="img-reg" role="button">
                        <img #filedata [src]="imageUrl" class="image" alt="profile image" (click)="file.click()">
                        </div>
                        <div>
                        <input id="filedata" type="file" name="filedata" accept=".jpg, .jpeg, .png"
                        (change)="handleFileInput($event)"  #file
                        />
                        <label for="filedata" role="button">{{imageUrl?'Update Image':'Add Image'}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div id="cropModal" class="cropper-modal" *ngIf="imageChangedEvent">
                <div class="cropper-wrap">
                    <div class="popup-head">
                        <div class="crop-date-position">
                            {{'BP_PM_CROP_PROFIMG' | customTranslation:translationData}}
                        </div>
                        <div class="button-region">
                            <button class="delta"  (click)="closeCropperModal()">{{buttonNames.CANCEL| customTranslation:translationData}}</button>
                            <button class="beta"   (click)="loadProfilePicture($event)" *ngIf="!imageSizeIsSmall && !imageSizeIsLarge">{{buttonNames.DONE | customTranslation: translationData}}</button>
                        </div>
                    </div>
                    <div class="img-cropper-wrapper">
                        <app-image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="maintainAspectRatio"
                        [aspectRatio]="aspectRatio"
                        [containWithinAspectRatio]="containWithinAspectRatio"
                        [cropperMinWidth]="cropperMinWidth"
                        [cropperMinHeight]="cropperMinHeight"
                        (croppedImage)="onCropped($event)"
                        (loadedImage)="onImageLoaded($event)"
                         ></app-image-cropper> 
                        <div class="upload_error" *ngIf="imageSizeIsSmall">
                            <p>{{'BP_PM_ERR_SMALLIMG' | customTranslation:translationData}}</p>
                        </div>
                        <div class="upload_error" *ngIf="imageSizeIsLarge">
                            <p>{{'BP_PM_LARGEIMG'| customTranslation:translationData}}</p>
                        </div>      
                    </div>   
                </div>
                
              </div>
        </form>
    </div>
</ng-template>
<app-common-modal [isHeaderVisible]="isHeaderVisible" [isCloseModal]="false" [closeButtonTitle]="buttonNames.CANCEL" [saveButtonTitle]="buttonNames.DONE"
    [isHeaderVisible]="true" [isFooterVisible]="true" component="create-edit-alias" [showModalContent]="editPopup"
    (saveModalEmitter)="saveProfileData()" (closeModalEmitter)="closeModal()" *ngIf="showEditPopup" [type]="popupType"></app-common-modal>